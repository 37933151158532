<template>
  <div>
    <el-form ref="form" :model="form" label-width="150px" style="display: flex">
      <div class="el-lt" style="width: 85%">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="16">
            <el-form-item label="上架日期">
              <el-date-picker
                v-model="form.value1"
                class="date"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="结算主体" :class="$i18n.locale" prop="cargoOwnerCode">
              <el-select v-model="form.cargoOwnerCode" style="width:100%;" value-key="id" clearable filterable>
                <el-option
                  v-for="item in cargoOwnerNameList"
                  :key="item.cargoOwnerName"
                  :label="item.cargoOwnerName"
                  :value="item.cargoOwnerCode"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="采购单号" :class="$i18n.locale">
              <el-input v-model="form.purchaseOrderCode" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收货单号" :class="$i18n.locale">
              <el-input v-model="form.collectCode" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="来源单号" :class="$i18n.locale">
              <el-input v-model="form.sourceCode" placeholder="请输入" />
            </el-form-item>
          </el-col>

        </el-row>

      </div>
      <el-row class="el-rt" style="width: 25%">
        <el-col class="row-center">
          <el-button type="primary" :loading="queryLoading" @click="_outBillVendor(1)">{{
            $t("page.search")
          }}</el-button>
          <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
          <el-button type="text" @click="showRow=!showRow">{{
            showRow ? $t("page.hide") : $t("page.expand")
          }}</el-button>
        </el-col>
      </el-row>
    </el-form>

    <el-table
      ref="tableDataRef"
      :data="tableData"
      :header-cell-style="{ background: '#fafafa' }"
      tooltip-effect="dark"
      max-height="500px"
      :row-key="getRowKeys"
      @selection-change="handleSelectionChange"
    >

      <el-table-column type="selection" width="50" :reserve-selection="true" />
      <el-table-column type="index" :label="$t('page.No')" align="center" width="50" />
      <el-table-column prop="estimationPayableCode" label="暂估应付单" width="180" align="center" />
      <el-table-column prop="cargoOwnerName" label="结算主体" />
      <el-table-column prop="settlementMethodI18" label="结算方式" />
      <!-- <el-table-column prop="collectTime" label="供应商编号" width="160" align="center" /> -->
      <el-table-column prop="vendorName" label="供应商" />
      <el-table-column prop="purchaseOrderCode" label="采购订单" width="180" align="center" />
      <el-table-column prop="collectCode" label="收货单" width="180" align="center" />
      <el-table-column prop="sourceCode" label="来源单据" width="180" align="center" />
      <el-table-column prop="paymentTypeI18" label="付款类型" />
      <el-table-column prop="totalPrice" label="单据金额" />
      <el-table-column prop="currency" label="币种" />
      <el-table-column prop="invoiceAmount" label="开票金额" />
      <el-table-column prop="taxRatio" label="税率(%)">
        <template slot-scope="scope">
          <!-- {{ scope.row.myId }} -->
          {{ ['',null,undefined].includes(scope.row.taxRatio)? 0 : scope.row.taxRatio }}
        </template>
      </el-table-column>
      <el-table-column prop="valueAddedTaxAmount" label="税额" width="110" align="center">
        <template slot-scope="scope">
          {{ ['',null,undefined].includes(scope.row.valueAddedTaxAmount)? 0 : scope.row.valueAddedTaxAmount }}
        </template>
      </el-table-column>
      <el-table-column prop="toWarehouseDate" label="到仓日期" width="140" />
      <el-table-column prop="shelfDate" label="上架日期" width="140" />

    </el-table>
    <div class="block">
      <Paging :pager="pager" end @pagination="pagerUpdate" />
    </div>

    <div
      style="display: flex;margin-top: 20px;"
    >
      <el-table
        :data="selectTable"
        border
        style="width: 100%"
      >
        <el-table-column
          prop="totalPrice"
          label="单据金额"
        />
        <el-table-column
          prop="currency"
          label="币种"
        />
        <el-table-column
          prop="invoiceAmount"
          label="开票金额"
        />
        <el-table-column
          prop="taxRatio"
          label="税率(%)"
        />
        <el-table-column
          prop="valueAddedTaxAmount"
          label="税额"
        />
      </el-table>
      <el-form ref="submitForm" :model="submitForm" label-width="150px" style="display: flex" :rules="rules">
        <el-col :span="16">
          <el-form-item label="发票开票月份" prop="collectMonth">
            <el-date-picker
              v-model="submitForm.collectMonth"
              value-format="yyyy-MM"
              type="month"
              placeholder="请选择"
            />
          </el-form-item>
        </el-col>
      </el-form>
      <div style="margin-left:35px">
        <el-button type="primary" :loading="clickLoading" @click="handleClick()">提 交</el-button>

      </div>

    </div>

    <!-- <el-row style="text-align:center;" class="mt-4">
      <el-col :span="12" :offset="6" style="text-align:center;">
      </el-col>

    </el-row> -->
  </div>
</template>

<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { getcargoownerList } from '@/api/scm-api'
import Paging from '@/components/Pagination'
import { deepClone } from '@/utils'
import { outBillVendor, invoiceOpen } from '@/api/vendorPurchase-api.js'

export default {
  components: { Paging },
  mixins: [commodityInfoDict],
  data() {
    return {
      clickLoading: false,
      pager: {
        total: 0,
        current: 1,
        size: 20
      },
      tableData: [],
      form: {

        value1: []
      },
      showRow: false,
      cargoOwnerNameList: [],
      multipleSelection: [],
      submitForm: {},
      queryLoading: false,
      loading: false,
      returnDatas: [],
      fullscreenLoading: false,

      index: '',
      now: [],
      uploadTableVisible: false,
      importdata: [],
      file: '',
      importLoading: false,
      fileList: [],
      exportLoading: false,
      title: '',
      rules: {
        collectMonth: [{ required: true, message: '必填', trigger: 'blur' }]
      },
      uploadTableDateVisible: false,
      dialogVisible: false,
      outBillDetailByStyleVOList: [],
      selectTable: [],
      vendorId: ''
    }
  },
  computed: {
    queryPageParams() {
      const obj = deepClone(this.form)
      const { value1 = [] } = obj
      const [invoiceDateStart, invoiceDateEnd] = value1 || []
      delete obj.value1
      return Object.assign({}, obj, this.pager, { vendorId: this.vendorId, invoiceDateStart, invoiceDateEnd },)
    }
  },
  mounted() {
    if (this.$route.query) {
      this.vendorId = this.$route.query.id
    }
    this._outBillVendor()
    this._getcargoownerList()
  },
  methods: {
    getRowKeys(row) {
      return row.id
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
      const arr = deepClone(val)
      const newArr = []
      arr.map(e => {
        let temp = true
        for (let i = 0; i < newArr.length; i++) {
          const v = newArr[i]
          if (e.taxRatio === v.taxRatio && e.currency === v.currency) {
            e.totalPrice ? v.totalPrice = Number(v.totalPrice) + Number(e.totalPrice) : ''
            e.invoiceAmount ? v.invoiceAmount = Number(v.invoiceAmount) + Number(e.invoiceAmount) : ''
            // e.totalPrice ? v.totalPrice = Math.round((v.totalPrice + e.totalPrice) * 100) / 100 : ''
            // e.totalPrice ? v.totalPrice = Math.round((v.totalPrice + e.totalPrice) * 100) / 100 : ''
            e.valueAddedTaxAmount ? v.valueAddedTaxAmount = Math.round((v.valueAddedTaxAmount + e.valueAddedTaxAmount) * 100) / 100 : ''
            console.log(v.totalPrice)
            v.totalPrice = Math.round((v.totalPrice + Number.EPSILON) * 100) / 100
            v.invoiceAmount = Math.round((v.invoiceAmount + Number.EPSILON) * 100) / 100
            // invoiceTaxAmount = Math.round((invoiceTaxAmount + Number.EPSILON) * 100) / 100
            temp = false
            break
          }
        }
        if (temp) {
          newArr.push(e)
        }
      })
      this.selectTable = newArr
    },
    // 结算主体
    async _getcargoownerList() {
      const { datas } = await getcargoownerList()
      this.cargoOwnerNameList = datas
    },
    async _outBillVendor(type) {
      try {
        this.queryLoading = true
        type && type === 1 ? this.pager.current = 1 : ''
        const { datas: { records = [], pager }} = await outBillVendor(this.queryPageParams)
        this.tableData = records
        this.form.cargoOwnerCode && this.$refs.tableDataRef.clearSelection()
        // this.tableData.map((e, index) => {
        //   console.log(index, e)
        //   e.myId = pager.current + '' + index
        // })
        this.pager = pager
      } finally {
        this.queryLoading = false
      }
    },
    // 重置查询条件
    resetClick() {
      this.form = this.$options.data().form
      // this.selectTable = []
      // this.multipleSelection = []
      this.$refs.tableDataRef.clearSelection()
      this._outBillVendor()
    },
    pagerUpdate(val) {
      console.log(this.multipleSelection)
      this.pager = val
      this._outBillVendor()
    },
    handleClick() {
      this.$refs['submitForm'].validate(valid => {
        if (valid) {
          console.log(this.multipleSelection)
          if (this.multipleSelection.length <= 0) {
            this.$message.warning('请至少选中一条数据')
            return
          }
          // 更新当前skuList至缓存list
          this.multipleSelection.map(item => {
            console.log(item)
            item.registerAmount = item.invoiceAmount
            item.collectMonth = (this.submitForm.collectMonth + '-01')
          })
          console.log(this.multipleSelection)
          this._invoiceOpen(this.multipleSelection)
        }
      })
    },
    async _invoiceOpen(data) {
      try {
        this.clickLoading = true
        const { msg, code } = await invoiceOpen(data)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
        } else { // 其他状态
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
        }

        this.clickLoading = false
        this.$router.go(-1)
      } finally {
        this.clickLoading = false
      }
    }

  }
}
</script>

<style scoped lang="scss">
.title {
  text-align: center;
  font-weight: 600;
  font-family: normal;
  letter-spacing: 1px;
  font-size: 20px;
}
/deep/.el-table .cell {
  .el-form-item {
    margin-bottom: 0px;
  }
}
html,
body {
  overflow-y: scroll;
}
html,
body {
  overflow: scroll;
  min-height: 101%;
}
html {
  overflow: -moz-scrollbars-vertical;
}
</style>
